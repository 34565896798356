
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































/* stylelint-disable selector-class-pattern */
.flexible-keylist__list {
  @extend %list-nostyle;

  counter-reset: key;
}

.flexible-keylist__item {
  margin-bottom: $spacing * 2;
  padding-bottom: $spacing * 2;
  border-bottom: 1px solid $c-light-grey;
  counter-increment: key;

  &:first-child {
    padding: $spacing * 2 0;
    border-top: 1px solid $c-light-grey;
  }
}

.flexible-keylist__item__title {
  .cpt-keyFigures & {
    @extend %ff-default;
    @extend %fw-light;

    font-style: italic;
  }

  ::v-deep b {
    @extend %ff-alt;
    @extend %fw-bold;

    color: $c-mustard;
    font-style: normal;
  }

  .cpt-keyFacts & {
    @extend %ff-alt;
    @extend %fw-bold;
    @include numbered-list-item(key);

    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: $spacing;
    font-size: 2rem;
    font-style: normal;
  }
}

@include mq('m') {
  .flexible-keylist__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $spacing * 2 col(0.75, 6);
  }

  .flexible-keylist__item {
    margin-bottom: 0;

    &:first-child {
      padding-top: 0;
      border-top: 0;
    }
  }
}

@include mq('l') {
  .flexible-keylist-inner {
    display: flex;

    /* stylelint-disable-next-line */
    .homepage .cpt-galleryWlink + .cpt-keyFigures & {
      padding-top: $spacing * 3;
      border-top: 1px solid $c-light-grey;
    }
  }

  .flexible-keylist__picture {
    margin-bottom: 12.6rem;
  }

  .flexible-keylist__content {
    display: flex;
  }

  .flexible-keylist__intro {
    width: col(3.5, 10);
    margin-right: col(1, 10);

    .g-intro {
      max-width: none;
    }
  }

  .flexible-keylist__list {
    width: col(5.5, 10);
  }
}

@include mq('xl') {
  .flexible-keylist__intro {
    width: col(3.1, 10);
  }

  .flexible-keylist__list {
    width: col(6, 10);
  }

  ol .flexible-keylist__item__title {
    font-size: 2.5rem;
  }
}
